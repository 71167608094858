<template>
  <div class="dropdown">
    <span>
      <slot name="title">
        <span class="no-icon">{{title}}</span>
        <b class="caret"></b>
      </slot>
    </span>
    <slot name="content">
      <div class="dropdown-content animated fadeIn faster p-0" >
        <slot></slot>
      </div>
    </slot>
  </div>
</template>
<script>
  export default {
    name: 'drop-down',
    props: {
      classes: String,
      title: String,
      faIcon: String,
      tag: {
        type: String,
        default: 'li'
      }
    },
    data () {
      return {
        isOpen: false
      }
    },
    methods: {
      toggleDropDown () {
        this.isOpen = !this.isOpen
        this.$emit('change', this.isOpen)
      },
      closeDropDown () {
        this.isOpen = false
        this.$emit('change', this.isOpen)
      }
    }
  }
</script>
<style>
.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1030;
  right: 0;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-large{padding:.6rem;margin:.125rem 0 0;min-width:30rem}

.dropdown-content .dropdown-item {
  color: #000;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
}

.dropdown-content .dropdown-item:hover {
  background-color: #0a9abc;
  border-color: #0a9abc;
  color: #fff;
}

.dropdown-content .dropdown-item.router-link-exact-active {
  background-color: rgb(10,154,188,0.30);
  border-radius: 5px;
}

  
</style>
