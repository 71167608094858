import Vue from 'vue'

Vue.prototype.$optionstable = {
    enabled: true,
    mode: 'records',
    perPage: 10,
    perPageDropdown: [5, 10, 25, 50],
    dropdownAllowAll: false,
    setCurrentPage: 1,
    nextLabel: 'Próximo',
    prevLabel: 'Anterior',
    rowsPerPageLabel: 'Linhas por página',
    ofLabel: 'de',
    pageLabel: 'página', // for 'pages' mode
    allLabel: 'Todos',
  }