import Vue from 'vue'
import App from './App.vue'

// import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/animate.min.css'
import './assets/css/style.css'
import './assets/css/modal.css'
import '@fortawesome/fontawesome-free/css/all.min.css'

Vue.config.productionTip = false

import router from './routes'

import store from './store'

// custom pipes
import './pipes'

// masks
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

// loading
import Load from './components/uicomponents/Load'
Vue.component('Load', Load)

import Dropdown from './components/uicomponents/Dropdown.vue'
Vue.component('drop-down', Dropdown)

import JsonExcel from "vue-json-excel"
Vue.component("downloadExcel", JsonExcel)

// SET GLOBAL VARIABLES
require('./globals')

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import VueSweetalert2 from 'vue-sweetalert2';
 
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
const options = {
  showClass: {
    popup: 'animated fadeInDown faster'
  },
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
}
Vue.use(VueSweetalert2, options);


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
