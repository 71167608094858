<template>
  <div class="loading" v-if="isLoading">
    <div class="loading1">
      <img src="@/assets/images/simbolo-branco.png" class="img-fluid rotate" alt="Logo loading">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Load',
  props: {
    isLoading: Boolean
  }
}
</script>

<style scoped>
.rotate {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(359deg);
  }
}

.loading {
  position: fixed;
  z-index: 1150;
  height: 200px;
  width: 200px;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; 
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}


</style>
